import type { ComboBoxParentPropsSingle } from "~/components/combobox";
import type { BillingPositionsQuery } from "~/types/api";
import { useLoaderData } from "@remix-run/react";
import _ from "lodash";
import { useMemo } from "react";
import ComboBox from "~/components/combobox";
import { useFetcherData } from "~/utils/remix";

export interface BillingPositionPickerProps extends ComboBoxParentPropsSingle {
  categories?: Array<string>;
  activeOnly?: boolean;
  valueField?: "id" | "description";
  initials?: boolean;
}

export default function BillingPositionPicker({
  categories = ["Billable", "Marketing & Sales", "Overhead"],
  activeOnly = true,
  valueField = "id",
  initials,
  ...rest
}: BillingPositionPickerProps) {
  const loader = useLoaderData<{
    billingPositionPicker?: BillingPositionsQuery;
  } | null>();
  const fetcher = useFetcherData<BillingPositionsQuery>(
    loader?.billingPositionPicker
      ? undefined
      : "/resources/billing-positions/picker"
  );

  const options = useMemo(() => {
    let bps = _.sortBy(
      loader?.billingPositionPicker?.billingPositions ||
        fetcher.data?.billingPositions ||
        [],
      "description"
    );
    bps = bps.filter((bp) => categories.includes(bp.category));
    if (activeOnly) {
      bps = bps.filter((bp) => bp.active || bp[valueField] === rest.value);
    }
    const grouped = _.groupBy(bps, "category");
    return Object.keys(grouped)
      .sort()
      .map((category) => ({
        label: category,
        options: grouped[category].map((bp) => ({
          value: bp[valueField],
          label: initials
            ? `${bp.description} (${bp.initials})`
            : bp.description
        }))
      }));
  }, [
    loader?.billingPositionPicker?.billingPositions,
    fetcher.data?.billingPositions,
    activeOnly,
    categories,
    valueField,
    rest.value,
    initials
  ]);

  return (
    <ComboBox placeholder="Select a Position" {...rest} options={options} />
  );
}
